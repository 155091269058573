@import "../utils/styles/variabiles";




.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: -3;

  .element {
      position: absolute;
      width: auto;
      height: auto;

      &.sponsorizare1 {
          width: 35vw;
          height: auto;
          top: calc(190vh - 10vh);
          left: calc(-12vw + 2vw);
          @media(max-width: 1024px){
              width: 30vw;
              top: calc(150vh - 10vh); 
          }
          @media(max-width: 480px){
              width: 60vw;
              top: calc(160vh - 10vh); 
         }
      }

      &.sponsorizare2 {
          width: 30vw;
          height: auto;
          top: calc(180vh - 5vh);
          right: calc(-9vw + 3vw);
          transform: rotate(8deg);
          @media(max-width: 1024px){
              width: 30vw;
              top: calc(120vh - 10vh); 
          }
          @media(max-width: 480px){
            width: 70vw;
            top: calc(80vh - 10vh); 
          }
      }
      &.sponsorizare3 {
        width: 35vw;
        height: auto;
        top: calc(25vh - 5vh);
        left: calc(-15vw + 3vw);
        transform: rotate(8deg);
        @media(max-width: 480px){
            width: 30vw;
            top: calc(130vh - 10vh); 
        }
        @media(max-width: 1024px){
            width: 35vw;
            top: calc(40vh - 10vh); 
        }
    }
    &.sponsorizare4 {
      width: 50vw;
      height: auto;
      top: calc(20vh - 5vh);
      right: calc(-27vw + 3vw);
      transform: rotate(8deg);
      @media(max-width: 480px){
          width: 30vw;
          top: calc(130vh - 10vh); 
      }
      @media(max-width: 1024px){
          width: 50vw;
          top: calc(40vh - 10vh); 
      }
  }
  &.sponsorizare5 {
    width: 40vw;
    height: auto;
    top: calc(270vh - 5vh);
    left: calc(-17vw + 3vw);
    transform: rotate(8deg);
    @media(max-width: 1024px){
        width: 50vw;
        top: calc(40vh - 10vh); 
    }
    @media(max-width: 480px){
      width: 90vw;
      top: calc(200vh - 10vh); 
      left: calc(-45vw + 3vw);
    }
}
&.sponsorizare6 {
  width: 30vw;
  height: auto;
  top: calc(280vh - 5vh);
  right: calc(-10vw + 3vw);
  transform: rotate(8deg);
  @media(max-width: 1024px){
      width: 50vw;
      top: calc(40vh - 10vh); 
  }
  @media(max-width: 480px){
    width: 70vw;
    right: calc(-30vw + 3vw);
    top: calc(250vh - 10vh); 
    }
}

  }
}




#casetaVerde {
    background-color: $main_green;
    margin: 1%;
    padding: 1%;
    border-radius: 15px;
    color: white;
    font-family: inherit;
}

a {
  color: whitesmoke;

  &:hover {
    text-decoration: underline;
  }
}

.content, .formular{
  color: whitesmoke;
  font-family: inherit;
  font-size: 1.3em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: none;
  min-height: 100vh;
  overflow: hidden;
  border-bottom: 2px solid rgba(0, 255, 47, 0.54);

  h2 {
    max-width: 70%;
    text-align: center;
    @media(max-width: 480px){
      font-size: 1rem;
    }
  }
  b{
    font-stretch: ultra-expanded;
  }

}

.clarifyButton {
    font-family: Designer, system-ui, Avenir, Helvetica, Arial, sans-serif;
    font-weight: 800;
    line-height: 1;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ccc;
    padding: 15px 20px;
    border: 1px solid rgba(61, 61, 61, 0.492);
    border-radius: 12px;
    position: relative;
    text-align: center;
    cursor: none;
    overflow: hidden;
    text-decoration: none;
    transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.formular {
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5%;

  background-color: $black1;

  padding: 5%;

  section {

    display: flex;
    flex-direction: column-reverse;
    text-indent: 10%;
    max-width: 95vw;

    input {
      margin-top: 2%;

      border-radius: 15px;
      text-indent: 10%;
      font-size: 1.2em;
      padding: 5px;

    }
  }

}
.formulareOffline2{
  margin-top: 50px;
  display: flex;
  flex-direction: column; /* Stacks elements vertically */
  align-items: center;    /* Centers horizontally */
  justify-content: center; /* Centers vertically (if parent has height) */
  text-align: center;  
  gap: 20px;


  a {
    background-color: $main_green;
    margin: 1%;
    padding: 3.5%;
    border-radius: 15px;
    transition: 0.2s all;

      color: white;
    &:hover {
      text-decoration: underline;
    background-color: lightgreen;
    }
  }
}
.formulareOffline {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
    place-items: center; 
    text-align: center;
  justify-items: center;
  align-items: center;

  grid-row-gap: 15px;

  a {
    font-family: Designer;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ccc;
    padding: 15px 20px;
    border: 1px solid rgba(61, 61, 61, 0.492);
    border-radius: 12px;


      color: white;
    &:hover {
      font-weight: bolder;
      border: 1px solid rgba(71, 71, 71, 0.774);
      text-shadow: 0 0 10px #00ff44;
      cursor: none;
      transform: scale(1.1) translateY(-2px);
    }
  }
}

section .buttonSeparator {
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  align-items: center;
  align-content: center;
  padding-top: 2%;

  input {
    text-indent: 0;
    margin: 0;
    &::file-selector-button {
      border-radius: 15px;
      background-color: $main_green;
      border: 1px groove $black2;
      padding: 5px 10px;
      transition: all 0.1s;
    }
  }
}

#Camera {
  display: none;
}

#camTxt {
  display: none;
  text-wrap: nowrap;
  text-indent: 5%;
}

#cameraButton {
  aspect-ratio: 1;
  width: 60px;
  background-image: url("../../images/camera.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
}


button {
  width: 40%;
  transition: all 0.2s;
  font-size: 1.6em;
  border-radius: 15px;
  background-color: $main_green;
  padding: 3px;

}


.clarifyButton::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: 1px solid rgba(71, 71, 71, 0.69);
  border-radius: 12px;
  pointer-events: none;
  cursor: none;
}

.clarifyButton:hover {
  font-weight: bolder;
  border: 1px solid rgba(71, 71, 71, 0.774);
  background-color: rgba(39, 39, 39, 0.3);
  text-shadow: 0 0 10px #00ff44;
  cursor: none;
  transform: scale(1.1) translateY(-2px);
}
button:hover, section input[type="file"]::file-selector-button:hover {
  cursor: none;
  background-color: $light_green;
}

#sd {
  &-container {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateY(-50%);
    transition: opacity 0.0s;
  }
}

.arrow {
  transition: opacity 0s;
  position: relative;
  width: 42px;
  height: 16px;
  opacity: 0;

  &::before,
  &::after {
    content: "";
    width:21px;
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    display: inline-block;
  }

  &::before {
    transform: rotate(45deg) translateX(25%)
  }

  &::after {
    transform: rotate(-45deg) translateX(-25%)
  }

  &:nth-child(1) {
    top: -50px;
    opacity: 1;
    animation: arrow2 1s ease 0s infinite;
  }

  &:nth-child(2) {
    top: -25px;
    animation: arrow1 1s ease 0.25s infinite;
  }
}

@keyframes arrow1 {
  from {
    opacity: 0;
    top: -25px;
  }

  to {
    opacity: 0;
    top: 0px;
  }

  50% {
    opacity: 1;
  }
}

@keyframes arrow2 {
  from {
    opacity: 0;
    top: -35px;
  }

  to {
    opacity: 0;
    top: -10px;
  }

  50% {
    opacity: 1;
  }
}


@media screen and (max-width: 1200px) {
  .content {
    align-items: center;
    align-content: center;

    font-size: 65%;

    h2 {
      text-align: center;
      padding: 3%;
    }

    p {
      max-width: 80%;
    }

    .clarifyButton {
      font-size: 1em;
      overflow: hidden;
    }
  }

  .formular {
    margin-top: 15%;
    display: none;
    flex-direction: column;

    section {
      margin-top: 3%;
    }

    .buttonSeparator {
      justify-content: center;
      // max-width: 80vw;
    }
  }

  #sd {
    &-container {
      position: fixed;
      left: 45%;
    }
  }
}
